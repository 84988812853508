import React, { useEffect, useRef, useState, forwardRef } from 'react'

const OpenViduVideoComponent = forwardRef(
  ({ rotateVideo, subscriber, isMuted, primary }, ref) => {
    const videoRef = useRef(null)
    const containerRef = useRef(null)
    const [containerDimensions, setContainerDimensions] = useState({
      width: 0,
      height: 0
    })
    const [isLandscape, setIsLandscape] = useState(null)
    const [isIOSApp, setIsIOSApp] = useState(false)

    // Define the function to update container dimensions
    const updateContainerDimensions = () => {
      if (containerRef.current) {
        setContainerDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        })
      }
    }

    // Adjust video container dimensions on window resize
    useEffect(() => {
      window.addEventListener('resize', updateContainerDimensions)
      updateContainerDimensions() // Initial call

      return () => {
        window.removeEventListener('resize', updateContainerDimensions)
      }
    }, [])

    useEffect(() => {
      if (subscriber && videoRef.current) {
        if (primary && subscriber?.stream?.connection?.data) {
          const data = JSON.parse(subscriber?.stream?.connection?.data)
          if (data?.platform === 'ios') {
            setIsIOSApp(true)
          } else {
            setIsIOSApp(false)
          }
        }
        subscriber.addVideoElement(videoRef.current)
        videoRef.current.addEventListener('loadedmetadata', () => {
          // Update container dimensions when video metadata is loaded
          updateContainerDimensions()

          // Determine if the video is landscape or portrait
          const videoWidth = videoRef.current.videoWidth
          const videoHeight = videoRef.current.videoHeight
          if (videoWidth && videoHeight) {
            setIsLandscape(videoWidth > videoHeight)
          }
        })
      }
    }, [subscriber])

    const isRotated = [90, -90, 270, -270].includes(rotateVideo)

    const getVideoStyleAndroid = () => {
      if (!videoRef.current) {
        return {}
      }
      const style = {
        transform: `rotate(${rotateVideo}deg)`,
        position: 'absolute',
        transformOrigin: 'center center'
      }

      if (!isRotated) {
        return { ...style, width: '100%', height: '100%', top: 0, left: 0 }
      }

      // Original video aspect ratio
      const videoAspectRatio =
        videoRef.current.videoWidth / videoRef.current.videoHeight

      // Adjusted dimensions based on rotation
      let adjustedWidth, adjustedHeight
      if (isRotated) {
        // Swap dimensions for rotated video
        adjustedWidth = videoRef.current.videoHeight
        adjustedHeight = videoRef.current.videoWidth
      } else {
        adjustedWidth = videoRef.current.videoWidth
        adjustedHeight = videoRef.current.videoHeight
      }

      // Calculate new dimensions based on container size
      let newWidth, newHeight
      const containerAspectRatio =
        containerDimensions.width / containerDimensions.height
      if (videoAspectRatio > containerAspectRatio) {
        newWidth = containerDimensions.height * (adjustedWidth / adjustedHeight)
        newHeight = containerDimensions.height
      } else {
        newWidth = containerDimensions.width
        newHeight = containerDimensions.width / (adjustedWidth / adjustedHeight)
      }

      // Centering the video inside the container (if needed)
      const offsetX = (containerDimensions.width - newWidth) / 2
      const offsetY = (containerDimensions.height - newHeight) / 2

      return {
        ...style,
        width: `${newHeight}px`,
        height: `${newWidth}px`,
        left: '0px',
        right: '0px',
        margin: 'auto'
      }
    }

    const getVideoStyle = () => {
      if (!videoRef.current) {
        return {}
      }
      const isPortrait = isLandscape ? false : true

      const style = {
        transform: `rotate(${rotateVideo}deg)`,
        position: 'absolute',
        transformOrigin: 'center center'
      }

      // Swapped dimensions if rotated
      const adjustedVideoWidth = isRotated
        ? videoRef.current.videoHeight
        : videoRef.current.videoWidth
      const adjustedVideoHeight = isRotated
        ? videoRef.current.videoWidth
        : videoRef.current.videoHeight

      const adjustedVideoAspectRatio =
        (adjustedVideoWidth / adjustedVideoHeight) * 1.333
      const containerWidth = containerDimensions.width
      const containerHeight = containerDimensions.height
      const containerAspectRatio = containerWidth / containerHeight

      let width, height
      if (adjustedVideoAspectRatio > containerAspectRatio) {
        // Video is wider than container
        if (isPortrait) {
          width = containerWidth
          height = containerWidth
        } else {
          width = containerWidth
          height = (containerWidth * 1.33) / adjustedVideoAspectRatio
        }
      } else {
        // Video is taller than container
        if (isPortrait) {
          width = containerHeight * (adjustedVideoAspectRatio * 1.333)
          height = containerHeight
        } else {
          width = containerHeight * adjustedVideoAspectRatio
          height = containerHeight
        }
      }

      // Center the video
      const left = (containerWidth - width) / 2
      const top = (containerHeight - height) / 2

      return {
        ...style,
        width: `${width}px`,
        height: `${height}px`,
        top: `${top}px`,
        left: `${left}px`
      }
    }

    return (
      <div className='stream-container' ref={containerRef}>
        {subscriber ? (
          <>
            <video
              autoPlay={true}
              controls
              muted={isMuted}
              ref={videoRef}
              style={isIOSApp ? getVideoStyle() : getVideoStyleAndroid()}
            />
          </>
        ) : (
          <h3 style={{ color: '#ffff' }}>Stream manager not found</h3>
        )}
      </div>
    )
  }
)

export default OpenViduVideoComponent
